
@import url("https://fonts.googleapis.com/css?family=Roboto:100&display=swap");

html, body, #root, .app, .editor {
    position: relative;
    width: 100%;
    height: 100%;
    overscroll-behavior: none;
}
.bottom {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%);
    padding: 5px;
    background: #222;
    box-sizing: border-box;
    text-align: center;
}
.bottom a {
    position: relative;
    display: inline-block;
    color: white;
    /* text-decoration: none; */
    font-weight: bold;
    font-size: 13px;
    padding: 15px 20px;
    box-sizing: border-box;
}
.badges img {
    padding: 2px;
}
.badgets a {
    margin: 2px;
}
